import styled from '@emotion/styled'
import { BonusMeterContainer, StarStreak } from 'assets/images';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  height: 33px;
  background-image: url(${BonusMeterContainer});
  background-size: 100% 100%;
  position: relative;
  background-color: #005400;
  border-radius: 17px;
  padding-left: 13px;
`;

export const MeterSquaresContainer = styled.div`
  display: flex;
  width: 90%;
  height: 20px;
`;

export const MeterSquaresEmpty = styled.div`
  height: 100%;
  width: 26px;
  ${props => 
    props.active ? `
      background-image: linear-gradient(to bottom right, #00FF00, #01BA01);
    ` : `
      background-color: #022402;
    `
  }
  margin-left: 2px;
  border-radius: 3.6px;
`;

export const GreenContainer = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Star = styled.div`
  background-image: url(${StarStreak});
  background-size: 100% 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -20px;
  margin-right: -20px;
`;